import React from 'react';

const DataRetentionPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <div className="container py-64">
        <div className="privacy-policy-header">
          <h1>Data Retention Policy</h1>
        </div>
      </div>

      <div>
        <div className="container-md py-80">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="guidelines innerp-text">
                <h2>Introduction</h2>
                <p>
                  This data retention policy sets out the obligations of SmartSocial (“us/we/our”) and the basis upon which we shall retain, review and destroy data held by us, or within our custody or control.
                </p>
                <p>
                  This policy applies to our entire organization including our officers, employees, agents, and sub-contractors and sets out what the retention periods are and when any such data may be deleted.
                </p>

                <h2>Objectives</h2>
                <p>
                  It is necessary to retain and process certain information to enable our business to operate. We may store data in the following places:
                </p>
                <ul className="return_policy">
                  <li>our own servers;</li>
                  <li>any third party servers;</li>
                  <li>potential email accounts;</li>
                  <li>desktops;</li>
                  <li>employee-owned devices (BYOD);</li>
                  <li>potential backup storage; and/or</li>
                  <li>our paper files.</li>
                </ul>
                <p>
                  This policy applies equally to paper, electronic media, and any other method used to store personal data. The period of retention only commences when the record is closed.
                </p>
                <p>
                  We are bound by various obligations under the law in relation to this and therefore, to comply with the law, information must be collected and used fairly, stored safely, and not disclosed to any other person unlawfully in respect of their personal data under the General Data Protection Regulation (“the Regulation”).
                </p>
                <p>
                  The Regulation defines “personal data” as any information relating to an identified or identifiable natural person (a data subject); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier, or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural, or social identity of that natural person.
                </p>

                <h2>Security and Storage</h2>
                <p>
                  All data and records are stored securely to avoid misuse or loss. We will process all personal data we hold in accordance with our IT Security Policy.
                </p>
                <p>
                  We will put in place procedures and technologies to maintain the security of all personal data from the point of collection to the point of destruction. Personal data will only be transferred to a data processor if there is an agreement by them to comply with those procedures and policies, or if there are adequate measures in place.
                </p>

                <p>
                  We will maintain data security by protecting the confidentiality, integrity, and availability of personal data, defined as follows:
                </p>
                <ul className="smartsocial_gdpr">
                  <li>
                    <strong>Confidentiality</strong> means that only people who are authorized to use the data can access it.
                  </li>
                  <li>
                    <strong>Integrity</strong> means that personal data should be accurate and suitable for the purpose for which it is processed.
                  </li>
                  <li>
                    <strong>Availability</strong> means that authorized users should be able to access the data if they need it for authorized purposes.
                  </li>
                </ul>

                <h2>Retention Policy</h2>
                <p>
                  Data retention is defined as the retention of data for a specific period of time and for backup purposes.
                </p>
                <p>
                  We shall not keep any personal data longer than necessary, but acknowledge that this will be dependent on the different types of documents and data that we have responsibility for. As such, our general data retention period shall be for a period of 3 months for Social Media Post and Lifetime for Name & Email.
                </p>

                <h2>Destruction and Disposal</h2>
                <p>
                  Upon expiry of our retention periods, we shall delete confidential or sensitive records categorized as requiring high protection and very high protection, and we shall either delete or anonymize less important documents.
                </p>
                <p>
                  Our Data Protection Officer – Jimit Bagadiya is responsible for the continuing process of identifying the records that have met their required retention period and supervising their destruction.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataRetentionPolicy;
