import React from "react";
import Header from "./Component/Header/Header";
import PageHeader from "./Component/PageHeader/PageHeader";
import PageContent from "./Component/PageContent/PageContent";
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import MainStatsPage from "./Component/MainStatsPage/MainStatsPage";
import ToolForEveryone from "./Component/ToolForEveryone/ToolForEveryone";
import G2Recommendation from "./Component/G2Recommendation/G2Recommendation";
import Footer from "./Component/Footer/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import "../src/Component/SchedulingPublishingSection/SchedulingPublishingSection.css"
import DataRetentionPolicy from "./pages/DataRetentionPolicy";

function App() {
  return (
    <Router>
    <Header />
    
    <Routes>
          <Route path="/" element={<>
            <PageHeader />
    <PageContent />
    {/* <SchedulingPublishingSection /> */}
    {/* <MainPage />
    <MainSocialEngagementPage />
    <MainTeamCollaborationPage />
    <MainWhiteLabelPage /> */}
    <MainStatsPage />
    <ToolForEveryone /> 
    <G2Recommendation />
    {/* <SocialPilotCTA /> */}

 
    </>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/privacy-policy.html" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsConditions />} />
          <Route path="/data-retention-policy" element={<DataRetentionPolicy />} />
          <Route path="/terms.html" element={<TermsConditions />} />
          <Route path="*" element={<RedirectToDashboard />} />
        </Routes>
        <Footer />
    </Router>
  );
}

const RedirectToDashboard = () => {
  const navigate = useNavigate();

  // Redirect to dashboard on page load
  React.useEffect(() => {
    navigate('/');
  }, [navigate]);

  return <div>Redirecting...</div>;
};


export default App;
