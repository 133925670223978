import React from "react";
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {


  return (
    <div className="privacy-policy-container">
      <div className="privacy-policy-header">
        <h1>Privacy Policy</h1>
        <p className="left-align">
          SmartSocial.us understands that your privacy is important to you and
          that you care about how your information is used and shared online.
          Please read this Privacy Policy carefully and ensure that you understand
          it. Your acceptance of Our Privacy Policy is deemed to occur upon your
          first use of Our Site. If you do not accept and agree with this Privacy
          Policy, you must stop using Our Site immediately.
        </p>
      </div>

      <div >
<div className="privacy-policy-content py-80">
<div className="row">
<div className="col-md-12">
<div className="guidelines">
<h2>Background</h2>
<p><strong>SmartSocial</strong> understands that your privacy is important to you and that you care about how your information is used and shared online. We respect and value the privacy of everyone who visits Our Site and will only collect and use information in ways that are useful to you and in a manner consistent with your rights and Our obligations under the law.</p>
<p>This Policy applies to Our use of any and all data collected by us concerning your use of Our Site. Please read this Privacy Policy carefully and ensure that you understand it. Your acceptance of Our Privacy Policy is deemed to occur upon your first use of Our Site. If you do not accept and agree with this Privacy Policy, you must stop using Our Site immediately.</p>
<h2><span>1.</span> Definitions and Interpretation</h2>
<p>In this Policy, the following terms shall have the following meanings:</p>
<p><strong>Account –</strong> means an account required to access and/or use certain areas and features of Our Site;</p>
<p><strong>Cookie –</strong> means a small text file placed on your computer or device by Our Site when you visit certain parts of Our Site and/or when you use certain features of Our Site. Details of the Cookies used by Our Site are set out in section 13, below;</p>
<p><strong>Our Site –</strong> means this website, <a href="https://www.smartsocial.us/">smartsocial.us</a>.</p>
<p><strong>UK and EU Cookie Law –</strong> means the relevant parts of the Privacy and Electronic Communications (EC Directive) Regulations 2003 as amended in 2004, 2011 and 2015; and</p>
<p><strong>We/Us/Our –</strong> means SmartSocial headquartered in Delaware, whose registered address is in Lewes, Delaware, United States.</p>
<h2><span>2.</span> Information About Us</h2>
<ul className="smartsocial_gdpr">
<li>2.1 Our Site, <a href="https://www.smartsocial.us/">SmartSocial</a>, is owned and operated by SmartSocial headquartered at 16192, Coastal Highway, Lewes, Delaware (United States) 19958.</li>
<li>2.2 Our data protection officer is Jimit Bagadiya; you can contact him at <a href="mailto:jimit.ab@gmail.com" rel=" noopener">jimit.ab@gmail.com.</a></li>
</ul>
<h2><span>3.</span> Scope – What Does This Policy Cover?</h2>
<p>This Privacy Policy applies only to your use of Our Site. It does not extend to any websites that are linked to Our Site (whether We provide those links or whether other users share them). We have no control over how your data is collected, stored, or used by other websites. We advise you to check the privacy policies of any such websites before providing any data to them.</p>
<h2><span>4.</span> What Data Do We Collect?</h2>
<p>Some data will be collected automatically by Our Site for further details, please see section 13 on Our use of Cookies, other data will only be collected if you voluntarily submit it and consent to Us using it for the purposes set out in section 5, for example, when signing up for an Account. Depending upon your use of Our Site, We may collect some or all of the following data:</p>
<ul>
<li>Name</li>
<li>Business/company name</li>
<li>Contact information such as email addresses</li>
<li>Demographic information such as post code</li>
<li>Financial information such as credit / debit card numbers</li>
<li>IP address (automatically collected)</li>
<li>Web browser type and version (automatically collected)</li>
<li>Operating system (automatically collected)</li>
<li>A list of URLs starting with a referring site, your activity on Our Site, and the site you exit to (automatically collected)</li>
<li>Billing Address</li>
<li>VAT No.</li>
<li>Social Profile URLs</li>
</ul>
<h2><span>5.</span> How Do We Use Your Data?</h2>
<ul className="smartsocial_gdpr">
<li><span>5.1 All personal data is stored securely in accordance with the EU General Data Protection Regulation (Regulation (EU) 2016/679) (GDPR). For more details on security, see section 6 below.</span></li>
<li><span>5.2 We use your data to provide the best possible products and services to you. It&nbsp; includes:</span></li>
<ul className="smartsocial_gdpr">
<li><span>5.2.1 Providing and managing your Account;</span></li>
<li><span>5.2.2</span> Providing and managing your access to Our Site;</li>
<li><span>5.2.3</span> Personalising and tailoring your experience on Our Site;</li>
<li><span>5.2.4</span> Supplying Our products and services to you;</li>
<li><span>5.2.5</span> Personalising and tailoring Our products and services for you;</li>
<li><span>5.2.6</span> Responding to communications from you;</li>
<li><span>5.2.7</span> Supplying you with newsletters, alerts, billing information etc. that you have subscribed to; you may unsubscribe or opt-out at any time changing email preferences on our site or at the bottom of your email;</li>
<li><span>5.2.8</span> Market research;</li>
<li><span>5.2.9</span> Analysing your use of Our Site and gathering feedback to enable Us to continually improve Our Site and your user experience;</li>
</ul>
<li><span>5.3 </span>In some cases, the collection of data may be a statutory or contractual requirement, and We will be limited in the products and services We can provide you without your consent for Us to be able to use such data</li>
<li><span>5.4 </span>With your permission and/or where permitted by law, We may also use your data for marketing purposes, which may include contacting you by email AND/OR telephone with information, news and offers on Our products AND/OR services. We will not, however, send you any unsolicited marketing or spam and will take all reasonable steps to ensure that We fully protect your rights and comply with Our obligations under the GDPR and the Privacy and Electronic Communications (EC Directive) Regulations 2003, as amended in 2004, 2011 and 2015.</li>
<li><span>5.5 </span>Under GDPR, we will ensure that your personal data is processed lawfully, fairly, and transparently, without adversely affecting your rights. We will only process your personal data if at least one of the following basis applies:</li>
<ul>
<li>a) you have given consent to the processing of your personal data for one or more specific purposes;</li>
<li>b) processing is necessary for the performance of a contract to which you are a party or to take steps at the request of you prior to entering into a contract;</li>
<li>c) processing is necessary for compliance with a legal obligation to which we are subject;</li>
<li>d) processing is necessary to protect the vital interests of you or of another natural person;</li>
<li>e) processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller; and/or</li>
<li>f) processing is necessary for the purposes of the legitimate interests pursued by a third party or by us, except where such interests are overridden by the fundamental rights and freedoms of the data subject which require protection of personal data, in particular where the data subject is a child.</li>
</ul>

<li><span>5.6</span> SmartSocial’s use and transfer to any other app of information received from Google APIs will adhere to the <a href="https://developers.google.com/terms/api-services-user-data-policy" rel="nofollow noopener noreferrer" target="_blank" title="api services user data policy">Google API Service User Data Policy</a>, including the <a href="https://support.google.com/cloud/answer/9110914#explain-types" rel="nofollow noopener noreferrer" target="_blank">Limited Use Requirements</a>.

</li><li><span>5.7</span> SmartSocial doesn’t use Google Workspace APIs to develop, improve, or train generalized AI and/or ML models.

<p className="mt-3">The Google information given by you, as a user, is used to provide or improve user-facing features that are prominent to your user experience. Google Drive™ is a trademark of Google Inc. Use of this trademark is subject to Google Permissions.</p></li>
</ul>






<h2><span>6.</span> How and Where Do We Store Your Data</h2>
<ul className="smartsocial_gdpr">
<li><span>6.1&nbsp; </span>We only keep your data for as long as We need to; to use it as described above in section 5, and/or for as long as We have your permission to keep it. In any event, We will conduct an annual review to ascertain whether we need to keep your data. We will delete your data if we no longer need it as per the terms of our <a href="https://www.smartsocial.us/data-retention-policy" title="Data Retention Policy" rel="dofollow">Data Retention Policy</a>.</li>
<li><span>6.2 </span>Some or all of your data may be stored or transferred outside of the European Economic Area (“the EEA”) (The EEA consists of all EU member states, plus Norway, Iceland and Liechtenstein). You are deemed to accept and agree to this by using Our Site and submitting information to Us. If We do store or transfer data outside the EEA, We will take all reasonable steps to ensure that your data is treated as safely and securely as it would be within the EEA and under the GDPR. Such actions may include, but not be limited to, the use of legally binding contractual terms between Us and any third parties We engage and the use of the EU-approved Model Contractual Arrangements.</li>
<li><span>6.3 </span>Data security is of great importance to Us, and to protect your data, We have put in place suitable physical, electronic, and managerial procedures to safeguard and secure data collected through Our Site.</li>
<li><span>6.4&nbsp; Steps We take to secure and protect your data include</span></li>
<ul className="inner_points_policy">
<li><span>6.4.1</span> We store user assets like images, videos, etc. in the S3 AWS cloud, and data in the AWS RDS.</li>
</ul>
<li><span>6.5 Notwithstanding the security measures that We take, it is essential to remember that the transmission of data via the internet may not be completely secure and that you are advised to take suitable precautions when transmitting to Us data via the internet.</span></li>
</ul>
<h2><span>7.</span> How Long Do We Retain Your Data</h2>
<p>Data retention is defined as the retention of data for a specific period of time and for backup purposes.</p>
<p>We shall not keep any personal data longer than necessary but acknowledge that this will be dependent on the different types of documents and data that we have responsibility for. As such, our general data retention period shall be for a period of 3 months for Social Media Post and Lifetime for Name &amp; Email.</p>
<p>From time to time, it may be necessary to retain or access historic personal data under certain circumstances, such as if we have contractually agreed to do so or if we have become involved in unforeseen events like litigation or business disaster recoveries.
</p><h2><span>8.</span> Do We Share Your Data?</h2>
<ul className="smartsocial_gdpr">
<li><span>8.1&nbsp; &nbsp;We may share your data with other companies in Our group. It includes Our subsidiaries AND Our holding company and its subsidiaries.</span></li>
<li><span>8.2 We may contract with third parties and API services to supply products and services to you on Our behalf. These may include payment processing, delivery of goods, search engine facilities, advertising, and marketing.

In some cases, the third parties may require access to some or all of your data. You can check out the terms of these API services. SmartSocial uses YouTube API Services and you can refer to {" "}
<a href="https://www.youtube.com/t/terms" title="YouTube Terms of Service" rel="noreferrer" target="_blank">YouTube Terms of Service</a> and <a href="https://policies.google.com/privacy?hl=en-US" title="Google Privacy Policy" rel="noreferrer" target="_blank">Google Privacy Policy</a>. Hence it is advisable to run through third-party terms yourself once. Taking the same case of YouTube, if you want to revoke access, you can do so from <a href="https://myaccount.google.com/permissions?pli=1" title="Google Security Settings" rel="noreferrer" target="_blank">Google Security Settings</a> Page.</span></li></ul></div><div className="mt-2">Where any of your data is necessary for such a purpose, We will take all reasonable steps to ensure that your data will be handled safely, securely, and in accordance with your rights, Our obligations, and the obligations of the third party under the law. We currently contract with:</div>
<ul className="inner_bullet_points mt-4">
<li>Amazon AWS – N. Virginia</li>
<li>Chargebee, Inc.</li>
<li><span >Datability Solutions, Inc.</span></li>
<li><span >Stripe, Inc.</span></li>
<li><span >PayPal, Inc.</span></li>
<li>Hubspot, Inc.</li>
<li>Mixpanel, Inc.</li>
<li>HelpScout, Inc.</li>
<li>Zoom Video Communications, Inc.</li>
<li>Google Analytics/Tag Manager</li>
<li><span >Profitwell – Subsidiary of 200OK, LLC.<span>&nbsp;</span></span></li>
<li>Calendly, LLC.</li>
<li>Facebook, Inc.</li>
<li>Google, LLC.</li>
<li>Capterra – Subsidiary of Gartner, Inc.</li>
<li><span >ActiveCampaign, LLC.</span></li>
</ul>


<ul className="smartsocial_gdpr">
<li><span>8.3 We may compile statistics about the use of Our Site, including data on traffic, usage patterns, user numbers, sales, and other information. All such data will be anonymized and will not include any personally identifying information. We may, from time to time, share such data with third parties such as prospective investors, affiliates, partners, and advertisers. Data will only be shared and used within the bounds of the law.</span></li>
<li><span>8.4 In certain circumstances, We may be legally required to share certain data held by Us, which may include your personal information, for example, where We are involved in legal proceedings, where We are complying with the requirements of legislation, a court order, or a governmental authority. We do not require any further consent from you to share your data in such circumstances and will comply as required with any legally binding request that is made of Us.</span></li>
</ul>
<h2><span>9.</span> What Happens If Our Business Changes Hands?</h2>
<ul className="smartsocial_gdpr">
<li><span>9.1 </span>We may, from time to time, expand or reduce Our business, and this may involve the sale and/or the transfer of control of all or part of Our business. Data provided by Users will, where it is relevant to any part of Our business so transferred, be transferred along with that part and the new owner or newly controlling party will, under the terms of this Privacy Policy, be permitted to use the data for the purposes for which we originally collected it.</li>
<li><span>9.2 </span>In the event that any of your data is to be transferred in such a manner, you will not be contacted in advance and informed of the changes.</li>
</ul>
<h2><span>10.</span> How Can You Control Your Data?</h2>
<ul className="smartsocial_gdpr">
<li>
<p>When you submit information via Our Site, you may be given options to restrict Our use of your data. We aim to give you strong controls on Our use of your data (including the ability to opt-out of receiving emails from Us, which you may do by unsubscribing using the links provided in Our emails and at the point of providing your details] AND [by managing your Account].</p>
</li>
</ul>
<h2><span>11.</span> Your Right to Withhold Information and Your Right to Withdraw Information After You Have Given it</h2>
<ul className="smartsocial_gdpr">
<li><span>11.1</span> You may access certain areas of Our Site without providing any data at all. However, to use all features and functions available on Our Site, you may be required to submit or allow for the collection of certain data.</li>
<li><span>11.2</span> You may restrict your internet browser’s use of Cookies. For more information, see section 13.</li>
<li><span>11.3</span> You may withdraw your consent for Us to use your personal data as set out in section in 5 at any time by contacting Us using the details set out in section 16, and We will delete Your data from Our systems. However, you acknowledge this may limit Our ability to provide the best possible products and services to you.</li>
<li>&nbsp;</li>
</ul>
<h2>12. How Can You Access, delete or transfer Your Data?</h2>
<p>You have the legal right to ask for a copy of any of your personal data held by Us (where such data is held). You can ask us to delete or transfer data that you own with us. Please contact Us with your request to access, transfer or delete at <a href="mailto:jimit.ab@gmail.com">jimit.ab@gmail.com</a> or using the contact details below in section 16.</p>
<h2><span>13.</span> What Cookies Do We Use and What For?</h2>
<ul className="smartsocial_gdpr">
<li><span>13.1&nbsp; Our Site may place and access certain first party Cookies on your computer or device. First party Cookies are those placed directly by Us and are used only by Us. We use Cookies to facilitate and improve your experience of Our Site and to provide and improve Our products and services. For more details, please refer to section 5, above, and section 13.6 below. We have carefully chosen these Cookies and have taken steps to ensure that your privacy is protected and respected at all times.</span></li>
<li><span>13.2&nbsp; By using Our Site, you may also receive certain third-party Cookies on your computer or device. Third-party Cookies are those placed by websites, services, and/or parties other than Us. We use third-party Cookies on Our Site for tracking visitors, distinguishing users and sessions, remarketing purposes, advertising services, usage, and engagement. For more details, please refer to section 5 above and to section 13.6 below. These Cookies are not integral to the functioning of Our Site.</span></li>
<li><span>13.3&nbsp; All Cookies used by and on Our Site are used in accordance with current English and EU Cookie Law.</span></li>
<li><span>13.4&nbsp; Before any Cookies are placed on your computer or device, subject to section 13.5 AND/OR section 13.8; you will be shown a pop-up requesting your consent to set those Cookies. By giving your consent to the placing of Cookies, you are enabling Us to provide the best possible experience and service to you. You may, if you wish, deny consent to the placing of Cookies; however, certain features of Our Site may not function fully or as intended.</span></li>
<li><span>13.5&nbsp; Certain features of Our Site depend on Cookies to function. UK and EU Cookie Law deems these Cookies to be “strictly necessary.” These Cookies are shown below in section 13.6. Your consent will not be sought to place these Cookies. You may still block these Cookies by changing your internet browser’s settings as detailed below in section 13.10, but please be aware that Our Site may not work as intended if you do so. We have taken great care to ensure that your privacy is not at risk by allowing them.</span></li>
<li><span>13.6</span> The following <strong>First Party Cookies</strong> may be placed on your computer or device:</li>
</ul>
<div className="table-responsive">


<table className="privacy_policy_table table table-bordered" cellspacing="0">
<tbody>
<tr>
<th >Name of Cookie</th>
<th >Purpose</th>
<th >Strictly Necessary</th>
</tr>
<tr>
<td >PHPSESSID</td>
<td >To maintain user sessions</td>
<td >Yes</td>
</tr>
<tr>
<td >tracking_id</td>
<td >To track affiliate referral users</td>
<td >Yes</td>
</tr>
</tbody>
</table>
</div>
<p className="third_party_cookies">The following <strong>Third Party Cookies</strong> may be placed on your computer or device:</p>
<div className="table-responsive">
<table className="privacy_policy_table">
      <thead>
        <tr>
          <th>Name of Cookie</th>
          <th>Provider</th>
          <th>Purpose</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            __utma,<br />__utmt,<br />__utmb,<br />__utmc,<br />__utmz, __utmv
          </td>
          <td>Google Analytics</td>
          <td>Used to distinguish users and sessions</td>
        </tr>
        <tr>
          <td>
            __hs, <br /> hubspotutk,<br /> __hstc,<br /> __hssc,<br /> __hssrc
          </td>
          <td>HubSpot</td>
          <td>To keep track of visitors and CRM purposes</td>
        </tr>
        <tr>
          <td>mp_</td>
          <td>Mixpanel</td>
          <td>To track usage and engagement</td>
        </tr>
        <tr>
          <td>__atuvc</td>
          <td>AddThis</td>
          <td>Cookies set by AddThis for social sharing</td>
        </tr>
        <tr>
          <td>
            hjClosedSurveyInvites,<br />_hjDonePolls,<br />_hjMinimizedPolls,<br />_hjDoneTestersWidgets,<br />_hjDoneSurveys,
          </td>
          <td>Hotjar</td>
          <td>To know how the visitor interacts with the survey pop-up</td>
        </tr>
        <tr>
          <td>
            _gw, <br />_gs,<br />_gu
          </td>
          <td>GetSiteControl</td>
          <td>To check our GetSiteControl widgets work correctly in accordance with custom Behavior and Targeting settings</td>
        </tr>
      </tbody>
    </table>
</div>
<ul className="smartsocial_gdpr">
<li><span>13.7</span> Our Site uses analytics services provided by Google Tag Manager, HubSpot, HotJar, ProfitWell, and Mixpanel. Website analytics refers to a set of tools used to collect and analyze usage statistics, enabling Us to understand better how people use Our Site. It enables Us to improve Our Site and the products and services offered through it. You do not have to allow Us to use these Cookies, as detailed below, however, while Our use of them does not pose any risk to your privacy or your safe use of Our Site, it does enable Us to continually improve Our Site, making it a better and more useful experience for you.</li>
<li><span>13.8 The analytics service(s) used by Our Site use(s) Cookies to gather the required information. Some of these cookies may be placed immediately when you first visit Our Site, and it may not be possible for Us to obtain your prior consent. You may remove these Cookies and prevent future use of them by following the steps set out below in section 13.10.</span></li>
<li><span>13.9</span> The analytics service(s) used by Our Site use(s) the following Cookies:</li>
</ul>
<div className="table-responsive">
<table className="privacy_policy_table table table-bordered" cellspacing="0">
<tbody>
<tr>
<th >Name of Cookie</th>
<th >First / Third Party</th>
<th >Provider</th>
<th >Purpose</th>
</tr>
<tr>
<td >_ga,<br/>_gid, <br/>_gat,<br/>_gac_, <br/>property-id/td&gt;</td>
<td >Third</td>
<td >Google Tag Manager</td>
<td >To Track User’s Behaviour</td>
</tr>
<tr>
<td >__hs, <br/>hubspotutk, <br/>__hstc, __hssc, <br/>__hssrct</td>
<td >Third</td>
<td >Hubspot</td>
<td >For CRM Purposes</td>
</tr>
<tr>
<td >mp_</td>
<td >Third</td>
<td >Mixpanel</td>
<td >To Track User’s Behaviour</td>
</tr>
</tbody>
</table>
</div>
<ul className="smartsocial_gdpr">
<li><span>13.10</span> You can choose to enable or disable Cookies in your internet browser. Most internet browsers also enable you to choose whether you wish to disable all cookies or only third-party cookies. By default, most internet browsers accept Cookies, but this can be changed. For further details, please consult the help menu in your internet browser or the documentation that came with your device.</li>
<li><span>13.11 You can choose to delete Cookies at any time; however, you may lose any information that enables you to access Our Site more quickly and efficiently, including, but not limited to, login and personalization settings.</span></li>
<li><span>13.12 It is recommended that you keep your internet browser and operating system up-to-date and that you consult the help and guidance provided by the developer of your internet browser and manufacturer of your computer or device if you are unsure about adjusting your privacy settings.</span></li>
<li><span>13.13 It is recommended that you keep your internet browser and operating system up-to-date and that you consult the help and guidance provided by the developer of your internet browser and manufacturer of your computer or device if you are unsure about adjusting your privacy settings</span></li>
</ul>
<h2><span>14.</span> Summary of Your Rights under GDPR</h2>
<p>Under the GDPR, you have:</p>
<ul className="smartsocial_gdpr">
<li><span>14.1</span> the right to request access to, deletion of or correction of, your personal data held by Us;</li>
<li><span>14.2</span> the right to complain to a supervisory authority;</li>
<li><span>14.3</span> be informed of what data processing is taking place;</li>
<li><span>14.4</span> the right to restrict processing;.</li>
<li><span>14.5</span> the right to data portability;</li>
<li><span>14.6</span> object to processing of your personal data;</li>
<li><span>14.7</span> rights with respect to automated decision-making and profiling (see section 15 below).</li>
</ul>
<p>To enforce any of the foregoing rights or if you have any other questions about Our Site or this Privacy Policy, please contact Us using the details set out in section 16 below.</p>
<h2><span>15.</span> Automated Decision-Making and Profiling</h2>
<ul className="smartsocial_gdpr">
<li><span>15.1</span> If We use your personal data for the purposes of automated decision-making and those decisions have a legal (or similarly significant) effect on You, then You have the right to challenge such decisions under GDPR, requesting human intervention, expressing their point of view, and obtaining an explanation of the decision from Us.</li>
<li><span>15.2</span> The right described in section 15.1 does not apply in the following circumstances:</li>
<ul className="inner_points_policy">
<li>a) The decision is necessary for the entry into, or performance of, a contract between the You and Us;</li>
<li>b) The decision is authorized by law; or</li>
<li>c) You have given your explicit consent.</li>
</ul>
<li><span>15.3</span> Where We use your personal data for profiling purposes, the following shall apply:</li>
<ul>
<li>a) Clear information explaining the profiling will be provided, including its significance and the likely consequences;</li>
<li>b) Appropriate mathematical or statistical procedures will be used;</li>
<li>c) Technical and organizational measures necessary to minimize the risk of errors and to enable such errors to be easily corrected shall be implemented; and</li>
<li>d) All personal data processed for profiling purposes shall be secured in order to prevent discriminatory effects arising out of profiling.</li>
</ul>
</ul>
<h2><span>16.</span> Contacting Us</h2>
<p>If you have any questions about Our Site or this Privacy Policy, please contact Us by email at <a href="mailto:jimit.ab@gmail.com">jimit.ab@gmail.com</a></p>
<p>Please ensure that your query is clear, particularly if it is a request for information about the data We hold about you (as under section 12, above).</p>
<h2>17. Changes to Our Privacy Policy</h2>
<p>We may change this Privacy Policy as we may deem necessary from time to time or as may be required by law. Any changes will be posted immediately on Our Site, and you will be deemed to have accepted the terms of the Privacy Policy on your first use of Our Site following the alterations. We recommend that you check this page regularly to keep up-to-date.</p>
</div>
</div>
</div>
</div>
    </div>
  );
};

export default PrivacyPolicy;
